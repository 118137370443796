import Swiper from 'swiper';

const certificatesSlider = () => {
	const certificatesHTML = document.querySelector('.js-certificates-slider');
	if (certificatesHTML) {
		const certSlider = new Swiper('.js-certificates-slider', {
			slidesPerView: 1,
			breakpoints: {
				660: {
					slidesPerView: 2,
					spaceBetween: 45,
					centeredSlides: true,
				},				
				1110: {
					slidesPerView: 3,
					spaceBetween: 45,
					// pagination: false,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				},
			}
		});

		certSlider.on('slideChange', (sliderObj) => {
			const mainWrapper = document.querySelector('.certificates');
			const nextBtn = mainWrapper.querySelector('.swiper-button-next');
			const prevBtn = mainWrapper.querySelector('.swiper-button-prev');
			const prevInactiveClass = 'swiper-button-prev--inactive'
			const nextInactiveClass = 'swiper-button-next--inactive'
			if (!mainWrapper || !nextBtn || !prevBtn) return
			if (sliderObj.isBeginning) {
				prevBtn.classList.add(prevInactiveClass)
			} else if (sliderObj.isEnd) {
				nextBtn.classList.add(nextInactiveClass)
			} else {
				prevBtn.classList.remove(prevInactiveClass)
				nextBtn.classList.remove(nextInactiveClass)
			}
		})

		const mainWrapper = document.querySelector('.certificates');
		if (mainWrapper) {
			const nextBtn = mainWrapper.querySelector('.swiper-button-next');
			if (nextBtn) {
				nextBtn.addEventListener('click', () => {
					certSlider.slideNext();
				})
			}
			const prevBtn = mainWrapper.querySelector('.swiper-button-prev');
			if (prevBtn) {
				prevBtn.addEventListener('click', () => {
					certSlider.slidePrev();
				})
			}
		}
	}
}

export default certificatesSlider;