const pageJS = () => {
	function checkHash() {
		if (window.hashLocation) {
			const element = document.querySelector(window.hashLocation);
			if (element) {
				document.querySelector(window.hashLocation).scrollIntoView({
					behavior: 'smooth'
				});
			}
		}
	}
	setTimeout(checkHash, 200);

	function setAnchors() {
		const allLinks = document.querySelectorAll('a');
		const anchorElements = Array.from(allLinks).filter((link) => {
			const href = new URL(link);
			if (href.hash) {
				return link;
			}
		})

		if (anchorElements.length === 0) return
		anchorElements.forEach(anchor => {
			const parent = anchor.parentElement
			console.log(parent);
			if (parent && parent.classList.contains('current-menu-item')) {
				parent.classList.remove('current-menu-item')
			}
			
			anchor.addEventListener('click', function (e) {
				e.preventDefault();
				const link = new URL(this.href);
				const id = link.hash;
				const element = document.querySelector(id);
				if (element) {
					const scrollVal = element.getBoundingClientRect().top + window.pageYOffset
					window.scrollTo({ top: scrollVal, behavior: 'smooth' })

					// element.scrollIntoView({
					// 	behavior: 'smooth',
					// 	instant: false,
					// });
				} else {
					window.location.href = link.toString();
				}
			});
		});
	}

	setAnchors();

	// Responsive nav
	function responsiveNav() {
		const burger = document.querySelector('.js-burger');
		const responsiveNav = document.querySelector('.js-responsive-nav');
		if (!burger || !responsiveNav) return

		const navElements = responsiveNav.querySelectorAll('a')
		navElements && navElements.forEach((navEl) => {
			navEl.addEventListener('click', function() {
				burger.classList.remove(burgerActiveClass);
				responsiveNav.classList.remove(responsiveNavActiveClass);
				document.querySelector('body').classList.remove('h-unscrollable')
			})
		})

		const responsiveNavActiveClass = 'header__responsive-nav--active';
		const burgerActiveClass = 'header__burger--active';

		burger.addEventListener('click', () => {
			responsiveNav.classList.toggle(responsiveNavActiveClass);
			burger.classList.toggle(burgerActiveClass);
			document.querySelector('body').classList.toggle('h-unscrollable')
		})

		window.addEventListener('resize', () => {
			const windowSize = window.innerWidth;
			if (windowSize >= 920) {
				burger.classList.remove(burgerActiveClass);
				responsiveNav.classList.remove(responsiveNavActiveClass);
				document.querySelector('body').classList.remove('h-unscrollable')
			}
		})
	}

	responsiveNav();
}

export default pageJS;